import {
  Button,
  ButtonSet,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Modal
} from "carbon-components-react";
import { Edit24, Delete24 } from "@carbon/icons-react";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { instance } from "../../services/axios";
import { InlineLoading } from "carbon-components-react";
import { auth } from "../../firebase";

export const ExhibitionsComponent = () => {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const responseRows = await instance.get("exhibition/");
        if (responseRows) {
          setRows(responseRows.data);
        }
      } catch (e) {
        console.error(e);
        setRows([]);
      }
      setLoading(false);
    })();
    return () => {};
  }, []);

  const editItem = (id) => history.push(`/ver-exibicao/${id}`);

  const promptDelete = (id) => {
    setDeletingId(id);
    setIsDeleteModalOpen(true);
  };

  const deleteItem = async () => { 
    setDeleteLoading(true);
    try {
      await instance.delete(`exhibition/${deletingId}`);
      const updatedRows = rows.filter((item) => item.documentId !== deletingId);
      setRows(updatedRows);
    } catch (e) {
      console.error(e);
    }
    setDeleteLoading(false);
    setIsDeleteModalOpen(false);
  };

  const headers = ["Nome", "Artista", "Data", ""];

  return (
    <>
      <h1>Exibições</h1>
      {loading ? (
        <InlineLoading
          status="active"
          iconDescription="Active loading indicator"
          description="Carregando..."
        />
      ) : (
        <>
          <ButtonSet style={{ paddingBottom: "20px" }}>
            <Button
              kind="primary"
              onClick={() => history.push("/nova-exibicao")}
            >
              Nova exibição
            </Button>
          </ButtonSet>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader key={header}>{header}</TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.title}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.artist || "-"}</TableCell>
                  <TableCell>{row.dateLabel}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <Button
                      kind="ghost"
                      renderIcon={Edit24}
                      iconDescription="Editar"
                      hasIconOnly
                      onClick={() => editItem(row.documentId)}
                    />
                    <Button
                      kind="ghost"
                      renderIcon={Delete24}
                      iconDescription="Remover"
                      hasIconOnly
                      onClick={() => promptDelete(row.documentId)}
                    /> 
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <Modal
        open={isDeleteModalOpen}
        modalHeading="Confirmar exclusão"
        modalLabel="Exclusão de exibição"
        primaryButtonText="Excluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={deleteItem}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '4rem'}}>
          <p>Tem certeza de que deseja excluir esta exibição?</p>
          {deleteLoading && (
            <InlineLoading
              description="Excluindo"
              status="active"
              style={{ marginTop: '0.5rem' }}
            />
          )}
        </div>
      </Modal>
    </>
  );
};
