import { Button, Form, TextArea, TextInput, Grid, Row, Column, InlineLoading, Toggle } from "carbon-components-react";
import { Save24 } from "@carbon/icons-react";
import { useEffect, useState } from "react";
import { instance } from "../../services/axios";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

export const AboutFormComponent = () => {

  const [about, setAbout] = useState({
    documentId: null,
    header: null,
    body: null,
    footer: null,
    index: null,
    isActive: true
  });
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const populateField = (field, value) => {
    setAbout({ ...about, [field]: value });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await instance.get(`about/${id}`);
        if (response) {
          setAbout({ ...about, ...response.data });
        }
      } catch (e) {
        console.error(e);
        setAbout([]);
      }
    })();
    return () => { };
  }, [id]);

  const save = async () => {
    setIsSubmitting(true);
    const saved = await instance.post("/about", about);
    setSuccess(!!saved.data);
    setIsSubmitting(false);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  return (
    <>
      <Form>
        <Grid>
          <h1>Sobre</h1>
          <Row>
            <Column lg={7}>
              <TextInput
                type="text"
                required
                id="title"
                labelText="Título"
                placeholder="Título"
                defaultValue={about.header}
                onChange={(e) => populateField("header", e.target.value)}
              />
              <br />
              <TextArea
                type="text"
                required
                id="body"
                labelText="Texto"
                placeholder="Texto"
                defaultValue={about.body}
                onChange={(e) => populateField("body", e.target.value)}
              />
              <br />
              <TextInput
                type="text"
                required
                id="footer"
                labelText="Rodapé"
                placeholder="Rodapé"
                defaultValue={about.footer}
                onChange={(e) => populateField("footer", e.target.value)}
              />
              <br />
              <TextInput
                type="text"
                required
                id="index"
                labelText="Ordem"
                placeholder="ex: 5"
                defaultValue={about.index}
                onChange={(e) => populateField("index", e.target.value)}
              />
              <br />
              <Toggle
                labelText="Ativo"
                labelA="Não"
                labelB="Sim"                
                id="isActive"
                onToggle={(e) => populateField("isActive", e)}
                toggled={about.isActive}                
              />
              <br />
            </Column>
          </Row>
          <Row>
            <Column lg={7} align="left">
              <br />
              {isSubmitting || success ? (
                <div style={{ display: "inline-block" }}>
                  <InlineLoading
                    description={success ? "Salvo com sucesso" : "Salvando..."}
                    status={success ? "finished" : "active"}
                  />
                </div>
              ) : (
                <Button type="submit" renderIcon={Save24} onClick={save}>
                  Salvar
                </Button>
              )}
            </Column>
          </Row>
        </Grid>
      </Form>
    </>
  );
};

