import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ExhibitionsComponent } from "./pages/Exhibitions";
import { ActivitiesComponent } from "./pages/Activities";
import { CreditsComponent } from "./pages/Credits";
import SecuredLayout from "./layouts/Secured";
import { ActivityFormComponent } from "./pages/Activities/form";
import { ExhibitionFormComponent } from "./pages/Exhibitions/form";
import { AboutsComponent } from "./pages/About";
import { AboutFormComponent } from "./pages/About/form";
import Protected from "./components/Protected";
import SignIn from "./pages/SignIn";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import { HomeComponent } from "./pages/Home";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) setIsAuthenticated(true);
            console.log(user)
        });
        return () => unsubscribe();
    }, []);

    return (
        <Router>
            <SecuredLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
                <Switch>
                    <Route exact path="/exibicoes">
                        <Protected isAuthenticated={isAuthenticated}>
                            <ExhibitionsComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/nova-exibicao">
                        <Protected isAuthenticated={isAuthenticated}>
                            <ExhibitionFormComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/ver-exibicao/:id">
                        <Protected isAuthenticated={isAuthenticated}>
                            <ExhibitionFormComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/atividades">
                        <Protected isAuthenticated={isAuthenticated}>
                            <ActivitiesComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/nova-atividade">
                        <Protected isAuthenticated={isAuthenticated}>
                            <ActivityFormComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/ver-atividade/:id">
                        <Protected isAuthenticated={isAuthenticated}>
                            <ActivityFormComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/creditos">
                        <Protected isAuthenticated={isAuthenticated}>
                            <CreditsComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/sobre">
                        <Protected isAuthenticated={isAuthenticated}>
                            <AboutsComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/ver-sobre/:id">
                        <Protected isAuthenticated={isAuthenticated}>
                            <AboutFormComponent />
                        </Protected>
                    </Route>
                    <Route exact path="/novo-sobre">
                        <Protected isAuthenticated={isAuthenticated}>
                            <AboutFormComponent />
                        </Protected>
                    </Route>
                    <Route exact path="">
                        <Protected isAuthenticated={!isAuthenticated}>
                            <SignIn />
                        </Protected>
                    </Route>

                </Switch>
            </SecuredLayout>
        </Router>
    );
};

export default App;
