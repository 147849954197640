import { Button } from "carbon-components-react";
import { Logout32 } from "@carbon/icons-react";
import { auth } from "../../firebase";

const LogoutButton = (props) => {
    return (
        <Button
            style={{
                color: 'white'
              }}
            kind="ghost"
            renderIcon={Logout32}
            onClick={() => 
                {
                    auth.signOut();
                    props.setIsAuthenticated(false);                
                }}

        >Logout</Button>);
};

export default LogoutButton;